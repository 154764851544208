<template>
  <v-layout column style="width: 100%; height: 100%; overflow-y: hidden">
    <Message />
    <v-container style="max-width: 1640px; height: 100%; overflow: hidden; position: relative">
      <v-layout align-center class="fill-height">
        <div style="height: 85vh; width: 100%; background: var(--v-bgContainer-base); border-radius: 12px">
          <v-overlay :value="loading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <div class="fill-height overflow-y-hidden px-5 d-flex flex-column" column>
            <div
              style="
                flex: none;
                height: 88px;
                width: 100%;
                text-align: center;
                border-bottom: 1px solid rgba(93, 101, 136, 0.5);
              "
            >
              <v-layout align-center class="fill-height px-5">
                <v-btn class="mr-2" dark fab small @click="$router.push('/admin')">
                  <v-icon>icon-arrow_left</v-icon>
                </v-btn>
                <h3 class="text-uppercase">ORGANIZATION MANAGEMENT</h3>
                <v-spacer />
                <div>{{ totalData }} organziations</div>
              </v-layout>
            </div>
            <div class="pt-5 d-flex flex-column" style="overflow-y: auto; overflow-x: hidden;">
              <div class="mb-5 d-flex">
                <div class="pr-2" style="width: 330px">
                  <v-text-field
                    v-model="keyword"
                    append-icon="mdi-magnify"
                    background-color="#2F324180"
                    dense
                    hide-details
                    label="Search"
                    placeholder="Search"
                    rounded
                    solo
                    @change="filterData"
                  >
                  </v-text-field>
                </div>
                <div class="pr-2" style="width: 330px">
                  <v-select
                    v-model="filter.permission"
                    :items="permissions"
                    background-color="#2F324180"
                    dense
                    hide-details
                    item-text="name"
                    item-value="value"
                    label="Permission"
                    multiple
                    placeholder="Permission"
                    rounded
                    solo
                    @change="filterData"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ filter.permission.length - 1 }} others)
                      </span>
                    </template>
                  </v-select>
                </div>
                <div class="pr-2" style="width: 330px">
                  <v-select
                    v-model="filter.menu"
                    :items="menus"
                    background-color="#2F324180"
                    dense
                    hide-details
                    item-text="name"
                    item-value="value"
                    label="Menu"
                    multiple
                    placeholder="Menu"
                    rounded
                    solo
                    @change="filterData"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ filter.menu.length - 1 }} others)
                      </span>
                    </template>
                  </v-select>
                </div>
                <v-spacer />
                <v-btn color="primary" rounded @click="() => $refs.organizationDialog.showCreate()">
                  <v-icon left>mdi-home-modern</v-icon>
                  Create organization
                </v-btn>
              </div>
              <div class="pb-3 mt-2" style="flex: 1;overflow-y: auto;">
                <v-simple-table>
                  <v-data-table
                    :headers="headers"
                    :items="organizations"
                    :options.sync="options"
                    :server-items-length="totalData"
                    class="elevation-1"
                    hide-default-footer
                  >
                    <template v-slot:[`item.access_services`]="{ item }">
                      <div
                        v-if="
                          item.access_services === null ||
                            (item.access_services && item.access_services.length === services.length)
                        "
                      >
                        <v-chip color="success" small>All service</v-chip>
                      </div>
                      <div v-else-if="item.access_services && item.access_services.length > 0">
                        <v-chip
                          v-for="service in item.access_services"
                          :key="service"
                          class="ma-1"
                          color="primary"
                          small
                          >{{ getServiceName(service) }}
                        </v-chip>
                      </div>
                      <div v-else-if="item.access_services.length === 0">
                        <v-chip color="red" small>No service</v-chip>
                      </div>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                      <div class="d-flex align-center justify-center">
                        <v-menu min-width="160" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon large v-bind="attrs" v-on="on">
                              <v-icon style="font-size: 34px; color: white">icon-menu-dots</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item v-for="(config, i) in listOrganizationConfigMenus" :key="i" @click="showEdit(item, config)">
                              <v-list-item-icon style="width: 50px">
                                <v-icon>{{ config.icon }}</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>{{ config.name }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </template>
                  </v-data-table>
                </v-simple-table>
              </div>
              <div class="mt-2 pb-2 d-flex justify-end">
                <v-pagination
                  v-model="page"
                  :length="totalPage"
                  :total-visible="10"
                  circle
                  @input="handChangePage"
                ></v-pagination>
              </div>
            </div>
          </div>
        </div>
      </v-layout>
    </v-container>

    <CreateOrganization ref="organizationDialog" />
    <!-- TODO: optimize this: use one -->
    <ConfigForm ref="configServiceDialog" title-config="Config Service" :list-all-datas="services" :list-data="organizationServices" @getData="getOrganizationService" @updateData="updateOrganizationService"/>
    <ConfigForm ref="configPermissionDialog" title-config="Config Permission" :list-all-datas="permissions" :list-data="organizationPermissions" @getData="getOrganizationPermission" @updateData="updateOrganizationPermission"/>
    <EditMenu ref="editmenu" /> <!-- dry -->
    <ConfigSetting ref="configSettingDialog" :settings="settings" @update="getOrganization" :type="'organization'"/>
  </v-layout>
</template>

<script>
import Message from '@/components/Message.vue'
import { getListOrganization, getServiceOrganization, updateServiceOrganization, getOrganizationPermission, updateOrganizationPermission  } from '@/api/organization'
import { getServices } from '@/api/services'
import { getMenus, getPermissions, getRole } from '@/api/user'
import { getSettings } from '@/api/settings'
import ConvertDate from '@/utils/convertDate'
import ThemeSwitcher from '@/layouts/components/ThemeSwitcher.vue'
import AppBarUserMenu from '@/layouts/components/AppBarUserMenu.vue'
import { mapState } from '@/store/ults'
import CreateOrganization from '@/views/pages/account-settings/CreateOrganization.vue'
import ConfigSetting from '@/components/setting-config.vue'
import EditMenu from './EditMenu.vue'
import { deleteProject, getListProject } from '@/api/project-api'
import ConfigForm from '../user-management/ConfigForm.vue'

export default {
  name: 'User',
  components: {
    AppBarUserMenu,
    ThemeSwitcher,
    Message,
    CreateOrganization,
    EditMenu,
    ConfigSetting,
    ConfigForm
  },
  data() {
    return {
      listOrganizationConfigMenus: [
        { name: 'Edit', icon: 'icon-edit_square', action: 'edit' },
        { name: 'Config Menu', icon: 'mdi-account-details', action: 'menu' },
        { name: 'Config Setting', icon: 'icon-settings', action: 'setting' },
        { name: 'Config Service', icon: 'mdi-account-wrench', action: 'service'},
        { name: 'Config Permission', icon: 'mdi-account-cog', action: 'permission' },
      ],
      ConvertDate,
      keyword: undefined,
      loading: false,
      options: {},
      filter: {
        menu: [],
        permission: [],
        role: [],
      },
      menus: [],
      permissions: [],
      organizationPermissions: [],
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Users',
          align: 'start',
          sortable: false,
          value: 'users_count',
        },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Phone', value: 'phone', sortable: false },
        { text: 'Created at', value: 'created_at', sortable: true },
        {
          text: 'Address',
          value: 'address',
          sortable: false,
        },
        { text: 'Action', value: 'action', align: 'center', sortable: false, width: '150px' },
      ],
      users: [],
      page: 1,
      perPage: 8,
      totalPage: 1,
      roles: [],
      totalData: 0,
      organizations: [],
      services: [],
      organizationServices: [],
      settings: {},
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  watch: {
    options: {
      handler() {
        this.getOrganization()
      },
      deep: true,
    },
  },
  mounted() {
    this.getOrganization()
    this.getServices()
    this.getMenus()
    this.getPermissions()
    this.getSettings()
    this.keyword = this.$route.query.keyword ? this.$route.query.keyword : undefined
    this.filterData()
  },
  methods: {
    showEdit(item, config) {
      switch(config.action) {
        case 'edit':
          this.$refs.organizationDialog.showEdit(item)
          break
        case 'menu':
          this.$refs.editmenu.showEdit(item.uuid)
          break
        case 'permission':
          this.$refs.configPermissionDialog.showCreate(item.uuid)
          break
        case 'setting':
          this.$refs.configSettingDialog.showDialog(item)
          break
        case 'service':
          this.$refs.configServiceDialog.showCreate(item.uuid)
          break
        default:
          break
      }
    },
    async getOrganizationService(uuid) {
      let data = await getServiceOrganization(uuid)
      this.organizationServices = data.data
    },
    async updateOrganizationService(params) {
      await updateServiceOrganization(params.uuid, { services: params.datas })
      this.getOrganization()
    },
    async getOrganizationPermission(uuid) {
      let data = await getOrganizationPermission(uuid)
      this.organizationPermissions = data.data
    },
    async updateOrganizationPermission(params) {
      await updateOrganizationPermission(params.uuid, { permissions: params.datas })
      this.getOrganization()
    },
    filterData() {
      this.page = 1
      this.getOrganization()
    },
    async getPermissions() {
      let data = await getPermissions()
      this.permissions = data.data
    },
    async getMenus() {
      let res = await getMenus({ per_page: 'all'})
      this.menus = res.data
    },
    async getSettings() {
      let data = await getSettings()
      this.settings = data.data

    },
    async getOrganization() {
      try {
        this.loading = true
        const res = await getListOrganization({
          permissions: this.filter.permission,
          menus: this.filter.menu,
          per_page: this.perPage,
          page: this.page,
          search: this.keyword,
          sort: this.options.sortBy[0]
            ? !this.options.sortDesc[0]
              ? this.options.sortBy[0]
              : `-${this.options.sortBy[0]}`
            : undefined,
        })
        this.organizations = res.data.data
        this.totalPage = res.data.last_page
        this.totalData = res.data.total
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getServices() {
      const res = await getServices()
      this.services = res.data.map(item => {
        return {
          ...item,
          value: item.id
        }
      })
    },
    async getUserRole() {
      let data = await getRole()
      this.roles = data.data
    },
    async handChangePage(page) {
      this.page = page
      this.getOrganization()
    },
    async deleteProject(project) {
      try {
        this.loading = true
        await deleteProject(project.uuid)
        await this.getListProject()
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getListProject() {
      const res = await getListProject({ per_page: 'all' })
      this.filterData.projects = res.data
    },
    getServiceName(id) {
      const service = this.services.find(item => item.id === id)
      return service ? service.name : null
    },
  },
}
</script>

<style scoped></style>
